<script lang="ts">
  import { cn } from '@utils/utils';

  let className: string | undefined = undefined;
  export { className as class };
</script>

<svg class={cn(className)} viewBox="0 0 116 50" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M40.6029 23.2003C40.4902 23.5171 40.6548 23.865 40.9715 23.9777C41.2881 24.0904 41.6085 23.9339 41.7487 23.609C44.3643 17.5466 50.1504 14.6041 56.6988 17.0363C61.7018 18.8944 62.9106 23.2285 62.9106 29.6707H33.2468V0.000244141H33.2461H48.9052L41.8866 19.7007L40.6029 23.2003ZM0.0218506 0.000244141V29.6707H12.432C12.432 29.6707 12.432 18.3062 29.6863 18.3062V11.9916H9.12366C8.78771 11.9916 8.51554 11.7193 8.51554 11.3833C8.51554 11.0473 8.78697 10.7751 9.12292 10.7751C19.0761 10.7751 29.6863 10.7046 29.6863 0.000244141L0.0218506 0.000244141ZM80.4868 0.000244141H65.6545V29.6707H80.4868V0.000244141ZM104.752 0.000244141H84.0465C84.0465 10.3775 97.4668 10.7743 101.824 10.7743C102.16 10.7743 102.431 11.0473 102.431 11.3826C102.431 11.7193 102.159 11.9916 101.823 11.9916H89.8801C86.6585 11.9916 84.0465 14.6041 84.0465 17.8263V29.67H97.9777L95.6016 21.3526H104.752C110.67 21.3526 115.467 16.5727 115.467 10.6764C115.467 4.78016 110.67 0.000244141 104.752 0.000244141Z"
    fill="#0BE094"
  />
  <path
    d="M4.06101 44.4944H2.29443V46.7052H0.775787V39.1636H3.94737C6.00321 39.1636 7.06729 40.1967 7.06729 41.767C7.06729 43.4613 5.84825 44.4944 4.06101 44.4944ZM3.9577 40.5583H2.29443V43.327H3.90604C4.98045 43.327 5.58998 42.8931 5.58998 41.9426C5.58998 40.9405 4.95979 40.5583 3.9577 40.5583Z"
    fill="#212121"
  />
  <path
    d="M10.8463 46.8085C8.91442 46.8085 7.84001 45.5585 7.84001 43.8125C7.84001 42.0873 8.96607 40.8062 10.8463 40.8062C12.7782 40.8062 13.8526 42.1183 13.8526 43.8125C13.8526 45.5068 12.7059 46.8085 10.8463 46.8085ZM10.8463 45.5378C11.9 45.5378 12.3133 44.8353 12.3133 43.8125C12.3133 42.7691 11.9 42.0563 10.8463 42.0563C9.82354 42.0563 9.37931 42.7898 9.37931 43.8125C9.37931 44.8456 9.77188 45.5378 10.8463 45.5378Z"
    fill="#212121"
  />
  <path
    d="M18.9129 42.7794L17.8179 46.7052H15.948L14.5016 40.9199H16.0616L17.0017 45.1349L17.0947 45.7547L17.2083 45.1349L18.4067 40.9199H19.6464L20.8138 45.1349L20.9171 45.7651L21.0101 45.1349L22.0432 40.9199H23.5722L21.9812 46.7052H20.1733L19.1299 42.7794L19.0162 42.2629L18.9129 42.7794Z"
    fill="#212121"
  />
  <path
    d="M24.1434 43.8435C24.1434 42.2112 25.2798 40.8062 27.129 40.8062C29.0919 40.8062 29.97 42.1803 29.97 43.6782V44.2361H25.6001C25.724 45.0729 26.2612 45.5585 27.16 45.5585C27.8315 45.5585 28.2035 45.3105 28.5444 44.7113L29.8151 45.3828C29.2779 46.2506 28.4514 46.8085 27.1807 46.8085C25.1765 46.8085 24.1434 45.5585 24.1434 43.8435ZM27.129 42.046C26.2922 42.046 25.7654 42.5005 25.6207 43.3063H28.4927C28.4514 42.5935 28.0692 42.046 27.129 42.046Z"
    fill="#212121"
  />
  <path
    d="M34.1081 40.8372C34.4904 40.8372 34.7693 40.8992 35.0069 41.0129L34.635 42.3972C34.3664 42.2526 34.0771 42.1803 33.7155 42.1803C33.0544 42.1803 32.6101 42.7175 32.6101 43.6886V46.7052H31.1018V40.9199H32.5998V42.2319C32.8581 41.2815 33.292 40.8372 34.1081 40.8372Z"
    fill="#212121"
  />
  <path
    d="M35.3117 43.8435C35.3117 42.2112 36.4481 40.8062 38.2973 40.8062C40.2602 40.8062 41.1383 42.1803 41.1383 43.6782V44.2361H36.7683C36.8923 45.0729 37.4295 45.5585 38.3283 45.5585C38.9998 45.5585 39.3717 45.3105 39.7126 44.7113L40.9833 45.3828C40.4461 46.2506 39.6196 46.8085 38.3489 46.8085C36.3447 46.8085 35.3117 45.5585 35.3117 43.8435ZM38.2973 42.046C37.4605 42.046 36.9336 42.5005 36.789 43.3063H39.661C39.6196 42.5935 39.2374 42.046 38.2973 42.046Z"
    fill="#212121"
  />
  <path
    d="M42.0308 43.8435C42.0308 41.8393 43.2911 40.8579 44.5722 40.8579C45.4709 40.8579 46.0495 41.2092 46.3904 41.984V38.4818H47.8987V46.7052H46.4007V45.5378C46.0391 46.3229 45.409 46.7672 44.4689 46.7672C43.0948 46.7672 42.0308 45.7857 42.0308 43.8435ZM46.504 43.8125C46.504 42.7691 46.0908 42.0563 45.0371 42.0563C44.0143 42.0563 43.5701 42.7898 43.5701 43.8125C43.5701 44.8456 43.9626 45.5378 45.0371 45.5378C46.0908 45.5378 46.504 44.8353 46.504 43.8125Z"
    fill="#212121"
  />
  <path
    d="M57.8741 43.7712C57.8741 45.7134 56.7481 46.7672 55.3741 46.7672C54.4649 46.7672 53.8554 46.3229 53.5042 45.5791V46.7052H52.0062V38.4818H53.5145V42.0666C53.9071 41.2401 54.5889 40.8579 55.498 40.8579C56.7997 40.8579 57.8741 41.767 57.8741 43.7712ZM56.3348 43.8125C56.3348 42.7691 55.9216 42.0563 54.8678 42.0563C53.8451 42.0563 53.4009 42.7898 53.4009 43.8125C53.4009 44.8456 53.7934 45.5378 54.8678 45.5378C55.9216 45.5378 56.3348 44.8353 56.3348 43.8125Z"
    fill="#212121"
  />
  <path
    d="M58.2229 40.9199H59.7725L61.2292 44.5047L61.4151 45.1142L61.6114 44.5047L63.0061 40.9199H64.5247L61.1982 48.9366H59.6279L60.63 46.6432L58.2229 40.9199Z"
    fill="#212121"
  />
  <path
    d="M67.876 39.1636H69.9215L71.9051 44.8973L71.9774 45.1969L72.0497 44.9076L74.0642 39.174L76.0478 39.1636V46.7052H74.5808V41.4984L74.6428 40.5376L74.4051 41.4778L72.5456 46.7052H71.3679L69.5186 41.4881L69.2914 40.61L69.343 41.4984V46.7052H67.876V39.1636Z"
    fill="#212121"
  />
  <path
    d="M77.2297 45.3518C77.2297 44.6907 77.5189 44.3084 77.9425 44.0295C78.3557 43.7609 78.9136 43.6059 80.0397 43.3786C81.0004 43.1823 81.1761 42.9964 81.1761 42.6555C81.1761 42.2836 80.8868 41.984 80.143 41.984C79.3888 41.984 78.9343 42.3972 78.7277 43.0584L77.364 42.6658C77.8495 41.3951 78.9343 40.8062 80.2153 40.8062C81.6616 40.8062 82.6121 41.5087 82.6121 42.9861V44.9696C82.6121 45.5068 82.767 45.6618 83.1493 45.6618C83.3042 45.6618 83.4385 45.6204 83.5522 45.5791V46.5502C83.3456 46.6845 82.9943 46.8085 82.5604 46.8085C81.6306 46.8085 81.2381 46.3953 81.1864 45.4965C80.8248 46.3333 80.0397 46.8085 79.0582 46.8085C77.9425 46.8085 77.2297 46.2713 77.2297 45.3518ZM81.2174 44.1328V43.7815C81.0728 43.9468 80.7628 44.0605 80.1533 44.1948C79.1409 44.4117 78.7483 44.5873 78.7483 45.0832C78.7483 45.5068 79.0582 45.7031 79.6471 45.7031C80.6905 45.7031 81.1967 44.763 81.2174 44.1328Z"
    fill="#212121"
  />
  <path
    d="M84.092 47.8829L85.4763 47.1184C85.8069 47.6659 86.1685 47.9552 86.9639 47.9552C87.8834 47.9552 88.4103 47.3973 88.4103 46.1886V45.5378C88.0487 46.3126 87.4185 46.7568 86.4784 46.7568C85.1044 46.7568 84.0403 45.7857 84.0403 43.8435C84.0403 41.8393 85.3007 40.8579 86.5817 40.8579C87.4908 40.8579 88.0797 41.2195 88.4206 42.0253V40.9199H89.9186V46.044C89.9186 48.2135 88.7718 49.2569 86.8503 49.2569C85.5279 49.2569 84.6085 48.7714 84.092 47.8829ZM88.5136 43.8125C88.5136 42.7691 88.1003 42.0563 87.0466 42.0563C86.0238 42.0563 85.5796 42.7898 85.5796 43.8125C85.5796 44.8456 85.9722 45.5378 87.0466 45.5378C88.1003 45.5378 88.5136 44.8353 88.5136 43.8125Z"
    fill="#212121"
  />
  <path
    d="M91.2576 39.1326C91.2576 38.5644 91.5882 38.1719 92.2081 38.1719C92.7969 38.1719 93.1482 38.5644 93.1482 39.1326C93.1482 39.6802 92.7969 40.0624 92.2081 40.0624C91.5882 40.0624 91.2576 39.6802 91.2576 39.1326ZM91.4126 40.9199H92.9312V46.7052H91.4126V40.9199Z"
    fill="#212121"
  />
  <path
    d="M94.119 43.8125C94.119 42.0873 95.2554 40.8062 97.1149 40.8062C98.8505 40.8062 99.584 41.6844 99.8939 42.9447L98.3546 43.2753C98.241 42.5522 97.8897 42.0563 97.0736 42.0563C96.0922 42.0563 95.6479 42.7898 95.6479 43.8125C95.6479 44.8456 96.0405 45.5378 97.0736 45.5378C97.9001 45.5378 98.2306 45.0419 98.3546 44.2671L99.9042 44.577C99.6253 45.8787 98.6749 46.8085 97.0736 46.8085C95.1417 46.8085 94.119 45.5585 94.119 43.8125Z"
    fill="#212121"
  />
  <path
    d="M104.355 46.8085C101.876 46.8085 100.698 45.0936 100.698 42.9344C100.698 40.8269 102.041 39.0603 104.376 39.0603C106.845 39.0603 108.064 40.7959 108.064 42.9344C108.064 45.0729 106.793 46.8085 104.355 46.8085ZM104.365 45.4138C105.801 45.4138 106.504 44.4117 106.504 42.9344C106.504 41.4571 105.791 40.4447 104.376 40.4447C103.033 40.4447 102.258 41.4881 102.258 42.9344C102.258 44.3807 102.929 45.4138 104.365 45.4138Z"
    fill="#212121"
  />
  <path
    d="M108.649 45.3002L109.92 44.2361C110.54 45.0316 111.159 45.4035 112.203 45.4035C113.019 45.4035 113.453 45.0006 113.453 44.4634C113.453 43.7196 112.533 43.5853 111.531 43.42C110.219 43.203 108.949 42.8518 108.949 41.3021C108.949 40.0728 109.951 39.0293 111.779 39.0293C113.133 39.0293 114.135 39.4219 115.003 40.5273L113.67 41.5294C113.174 40.8166 112.616 40.4343 111.624 40.4343C110.788 40.4343 110.374 40.8579 110.374 41.3331C110.374 42.0769 111.201 42.1906 112.317 42.3765C113.536 42.5832 114.879 42.9137 114.879 44.4944C114.879 45.889 113.804 46.8188 112.079 46.8188C110.478 46.8188 109.362 46.2506 108.649 45.3002Z"
    fill="#212121"
  />
</svg>
