<script lang="ts">
  import { Button } from '@components/ui/button';
  import { Typography } from '@components/ui/typography';
  import { REDIRECT_LINKS_CONST } from '@core/const/redirect-links.const';
</script>

<div
  class="fixed z-10 top-0 left-0 right-0 bottom-0 bg-primary pt-[120px] pb-6 flex flex-col content-between px-4 h-full"
>
  <div class="flex flex-col w-full divide-y divide-black-carbon-main">
    <Typography href={REDIRECT_LINKS_CONST.whyFlipLanding} variant="a" size="xxl" class="py-6">
      Why Flip
    </Typography>
    <Typography href={REDIRECT_LINKS_CONST.magicOsLanding} variant="a" size="xxl" class="py-6">
      MagicOS
    </Typography>
    <Typography href={REDIRECT_LINKS_CONST.adsProgramLanding} variant="a" size="xxl" class="py-6">
      Ads program
    </Typography>
  </div>
  <div id="mobile-menu-buttons" class="gap-2 flex gap-2 flex-col mt-auto">
    <Button size="xl" variant="secondary" href={REDIRECT_LINKS_CONST.magicOS} target="_blank">
      Log in
    </Button>
    <Button size="xl" href={REDIRECT_LINKS_CONST.sellWithFlip} target="_blank">Get Started</Button>
  </div>
</div>

<style lang="postcss">
  :root #mobile-menu-buttons [data-button-root] {
    --primary: 0 0% 13%;
    --primary-foreground: 45 29% 97%;
  }
</style>
