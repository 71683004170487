<script lang="ts">
  import { cn } from '@utils/utils';
  import { Menu, X } from 'lucide-svelte';

  import MagicLogo from '@components/SVGs/MagicLogo.svelte';
  import { Button } from '@components/ui/button';
  import { Typography } from '@components/ui/typography';
  import { REDIRECT_LINKS_CONST } from '@core/const/redirect-links.const';
  import { isSsr } from '@core/utils/is-ssr';

  import { MobileMenu } from './MobileMenu';

  let menuOpened = false;

  const toggleMenu = () => {
    menuOpened = !menuOpened;

    document.body.style.overflow = menuOpened ? 'hidden' : '';
  };

  const _currentPath = isSsr() ? '' : window.location.pathname;
</script>

<header
  id="magic-os-header"
  class="fixed z-20 top-6 md:top-[56px] left-0 w-full pr-[var(--scrollbar-width)]"
>
  <div class="container">
    <section
      class="rounded-[90px] pl-6 pr-2 py-2 md:p-2 md:pl-10 bg-off-white-light flex items-center justify-between"
    >
      <a href={REDIRECT_LINKS_CONST.whyFlipLanding}>
        <MagicLogo class="w-full max-w-[75px] md:max-w-[100px] mr-[80px]" />
      </a>

      <div class="mr-auto gap-10 hidden md:flex text-black-carbon">
        <Typography
          href={REDIRECT_LINKS_CONST.whyFlipLanding}
          variant="a"
          size="sm"
          class={cn({
            'font-bold': _currentPath.includes(REDIRECT_LINKS_CONST.whyFlipLanding)
          })}
        >
          Why Flip
        </Typography>
        <Typography
          href={REDIRECT_LINKS_CONST.magicOsLanding}
          variant="a"
          size="sm"
          class={cn({
            'font-bold': _currentPath.includes(REDIRECT_LINKS_CONST.magicOsLanding)
          })}
        >
          MagicOS
        </Typography>
        <Typography
          href={REDIRECT_LINKS_CONST.adsProgramLanding}
          variant="a"
          size="sm"
          class={cn({
            'font-bold': _currentPath.includes(REDIRECT_LINKS_CONST.adsProgramLanding)
          })}
        >
          Ads program
        </Typography>
      </div>

      <div id="buttons" class={cn('gap-2 hidden', { 'lg:flex': !menuOpened })}>
        <Button size="xl" variant="secondary" href={REDIRECT_LINKS_CONST.magicOS} target="_blank">
          Log in
        </Button>
        <Button size="xl" href={REDIRECT_LINKS_CONST.sellWithFlip}>Get Started</Button>
      </div>

      <Button class={cn('size-10 p-0', { 'lg:hidden': !menuOpened })} on:click={toggleMenu}>
        {#if menuOpened}
          <X size={16} />
        {:else}
          <Menu size={16} />
        {/if}
      </Button>
    </section>
  </div>
</header>

{#if menuOpened}
  <MobileMenu />
{/if}

<style lang="postcss" global>
  :root #magic-os-header [data-button-root] {
    --primary: 0 0% 13%;
    --primary-foreground: 45 29% 97%;
  }
</style>
